.login-page{
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    /* padding: 10px; */
}

.login-form-container{
    background: #f8efe6;
    max-width: 450px;
    width: 100%;
    margin: 0 5px;
    border-radius: 7px;
    padding: 10px 10px 25px;
}

.login-form-container h2{
    text-align: center;
}

.login-form-content{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}
.login-input-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.login-input-container label{
    font-size: 18px;
}

.login-input-container input{
    font-size: 20px;
    padding: 7px 13px;
    border-radius: 5px;
    font-family: 'Roboto',
    sans-serif;
    border: 1px solid #F28F2F95;
    outline: none;
}

.login-submit-container button{
    width: 90%;
  display: block;
  margin: 15px auto 0;
  font-size: 24px;
  padding: 7px 9px;
  background: #EC3034;
  border: none;
  outline: none;
  border-radius: 7px;
  color: #ffffff;
  font-family: 'Allerta',
  sans-serif;
  cursor: pointer;
  font-weight: 900;
  transition: 0.2s ease;
}
.login-submit-container button:hover{
    background: #F28F2F;
    color: #ffffff;
}

.login-error-message{
    color: #EC3034;
    text-align: center;
    margin: 5px auto;
}


.dashboard-header{
    position: fixed;
    top: 0;
    left: 0;
   
    padding: 3px 0;
    width: 100%;
    
    height: 100px;
    background: #f8efe6;
    border-bottom: 1px solid #F28F2F;
    z-index: 100;
}

.dashboard-header-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    height: 100%;
    padding: 0 15px;
}
.dashboard-header-left{
    display: flex;
    flex-direction: row;
    gap: 25px;
    width: max-content;
    height: 100%;
    align-items: center;
}
.dashboard-header-right{
    display: flex;
    flex-direction: row;
    gap: 25px;
    width: max-content;
    height: 100%;
    align-items: center;
}
.dashboard-header-logo{
    height: 100%;
    aspect-ratio: 1/1;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard-header-menu-button{

}
.dashboard-header-menu-button button,
.dashboard-header-logout-button{
    background: #EC3034;
    color: #ffffff;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    width: 60px;
    height: 60px;
    border: none;
    outline: none;
    transition: 0.3s ease;
}
.dashboard-header-menu-button button:hover,
.dashboard-header-logout-button:hover{
    background: #F28F2F;
}
.dashboard-header-user-name{
    font-size: 20px;
    font-weight: 900;
}

.dashboard-layout{
    position: relative;
    background: #f8efe6;
}

.dashboard-menu{
    position: fixed;
    z-index: 90;
    top: 100px;
    left: 0;
    max-width: 300px;
    width: 100%;
    transform: translateX(-100%);
    transition: 0.3s ease;
    background: #F28F2F;
    height: calc(100vh - 100px);
}


.dashboard-menu.active{
    transform: translateX(0);
}


.dashboard-menu-nav{
    width: 100%;
    display: block;
    position: relative;
    padding: 20px 15px;
    color: #f8efe6;
}

.dashboard-menu-nav-ul{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0;
    list-style: none;
}

.dashboard-menu-nav-a{
    text-decoration: none;
    color: #f8efe6;
    font-size: 24px;
    font-weight: 900;
}

.dashboard-menu-nav-a.active{
    color: #EC3034;
}

.dashboard-content{
    position: relative;
    z-index: 0;
    display: block;
    min-height: 100vh;
    background: #f8efe6;
    padding: 125px 10px 25px;
}


.table-container{
    max-height: calc(100vh - 300px);
    overflow-x: auto;
    overflow-y: auto;
}

.submission-table{
    width: 100%;
    border-collapse: collapse;
}
.submissions-table{

    border-collapse: collapse;
}

.submissions-table tr{
    border-collapse: collapse;
    border: 1px solid #ddd;
}
.submissions-table th, 
.submissions-table td {
    border-collapse: collapse;
  padding: 10px 25px;
  text-align: left;

}

.submissions-table tbody tr:nth-of-type(odd) {
    background: #fafafa;
}
.submissions-table tbody tr:nth-of-type(even) {
    background: #eaeaea;
}

.submissions-table thead th {
    position: sticky; /* Fixează coloanele de header */
    top: 0; /* Păstrează poziția fixată la scroll pe Y */
    background-color: #f8f8f8;
    z-index: 1; /* Asigură-te că header-ul este deasupra */
  }
  

  .column-id{
    min-width: 50px;
  }
  .column-date{
    min-width: 200px;
  }
  .column-name{
    min-width: 200px;
  }
  
  .column-prize{
    min-width: 120px;
  }

  .column-judet,
  .column-oras{
    min-width: 200px;
  }

  .column-signature,
  .column-recived{
    min-width: 100px;
  }

  .column-actions{
    min-width: 300px;
  }

  .column-actions-container{
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  .give-prize-button{
    padding: 5px 10px;
    border-radius: 7px;
    font-size: 18px;
    background: #EC3034;
    color: #ffffff;
    border: none;
    outline: none;
    transition: 0.3s ease;
  }

  .give-open-sign-pv-button{
    padding: 7px 10px;
    border-radius: 7px;
    font-size: 18px;
    background: #F28F2F;
    color: #ffffff;
    border: none;
    outline: none;
    transition: 0.3s ease;
  }
  .give-open-sign-pv-button:hover,
  .give-prize-button:hover{
    opacity: 0.7;
  }

  .table-container {
    /* Adaugă border pentru containerul tabelului */
    border: 1px solid #ddd;
  }

  .admin-page-filters{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }

  .admin-page-filter-container{
    width: 100%;
    display: flex;
    flex-direction: column;
gap: 3px;
  }

  .admin-page-filter-container input,
  .admin-page-filter-container select{
    padding: 7px 13px;
    font-size: 20px;
  }

  .prizes-details-table{
    width: 100%;
    font-size: 18px;
    text-align: left;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .table-last-col{
    text-align: right;
  }

  .prizes-details-table,
  .prizes-details-table tr,
  .prizes-details-table th,
  .prizes-details-table th{
    border-collapse: collapse;
  }

  .prizes-details-table tr{
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
    
  }

  .prizes-details-table td,
  .prizes-details-table th
  {
    padding: 7px 10px;
  }

  .prizes-details-table input{
    font-size: 20px;
    padding: 7px 13px;
    border-radius: 5px;
    font-family: 'Roboto',
    sans-serif;
    font-weight: 600;
    text-align: center;
    border: 1px solid #F28F2F95;
    outline: none;
  }

  .prizes-save-button{
    width: 90%;
  display: block;
  margin: 15px auto 0;
  font-size: 24px;
  padding: 7px 9px;
  background: #EC3034;
  border: none;
  outline: none;
  border-radius: 7px;
  color: #ffffff;
  font-family: 'Allerta',
  sans-serif;
  cursor: pointer;
  font-weight: 900;
  transition: 0.2s ease;
}
.prizes-save-button:hover{
    background: #F28F2F;
    color: #ffffff;
}

.sign-pv-container {
  align-items: center;
  display: flex;
  height: calc(100vh - 100px);
  justify-content: center;
  left: 0;
  position: fixed;
  top: 100px;
  width: 100%;
  z-index: 100000;
}

.sign-pv-box{
  background-color: #F28F2F;
  border-radius: 7px;
  padding: 20px 10px;
}

.export-pdf-button{
  width: max-content;
  display: block;
  margin: 15px auto 10px 0;
  font-size: 20px;
  padding: 7px 9px;
  background: #EC3034;
  border: none;
  outline: none;
  border-radius: 7px;
  color: #ffffff;
  font-family: 'Allerta',
  sans-serif;
  cursor: pointer;
  font-weight: 900;
  transition: 0.2s ease;
}
.export-pdf-button:hover{
  background: #F28F2F;
  color: #ffffff;
}