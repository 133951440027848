@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

body {
  margin: 0;
  font-family: 'Roboto',
    sans-serif;
    color: #3a3a3a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffc929;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-background-container{
  position: absolute;
  top: 0;
  left: 0;
  top: -50vh;
  width: 100%;
  height: 150vh;
}

.page-background-container::after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, #ffc929 10%, #ffc92900 50%, #ffc929 90%);
}

.page-background{
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 0;
}


.page-main{
  width: 100%;
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #ffc929;
  min-height: 100vh;
  padding: 0 10px;
}

.content-box{
  position: relative;
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 50px;
  padding-top: 50px;
  /* overflow: hidden; */

}


.page-content{
  position: relative;
  z-index: 1;
}

.start-register-page{

width: 100%;
margin:50px auto;
}

.start-register-page h3{
  font-size: 24px;
  text-align: center;
}

.start-register-prize-item {
  width: 32%;
}

.start-register-prize-item-img {
  width: 100%;
  display: block;
  aspect-ratio: 1 / 1;
  object-fit: contain;
  filter: drop-shadow(0px 0px 1px #ffffff) drop-shadow(0px 0px 1px #ffffff) drop-shadow(0px 0px 1px #ffffff) drop-shadow(0px 0px 1px #ffffff) drop-shadow(0px 0px 1px #ffffff) drop-shadow(0px 0px 1px #ffffff) drop-shadow(0px 0px 0px #ffffff) drop-shadow(0px 0px 0px #ffffff);
}

.start-register-big-prize-item-img-container{
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}

.start-register-big-prize-item-img{
  width: 100%;
  display: block;
  aspect-ratio: 1 / 1;
  object-fit: contain;
  filter: drop-shadow(0px 0px 1px #ffffff) drop-shadow(0px 0px 1px #ffffff) drop-shadow(0px 0px 1px #ffffff) drop-shadow(0px 0px 1px #ffffff) drop-shadow(0px 0px 1px #ffffff) drop-shadow(0px 0px 1px #ffffff) drop-shadow(0px 0px 0px #ffffff) drop-shadow(0px 0px 0px #ffffff);
}

.start-register-prizes-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2%;
}



.start-register-page h1 {
  
}

.start-register-page h2 {
  max-width: 600px;
  margin: 50px auto 25px;
  text-align: center;
}

.start-register-page-headliner{
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
}
.start-register-page-headliner h1{
  text-align: center;
  margin: 0 auto;

}
.start-register-page-headliner h2{
  margin: 10px auto;
}

.start-register-button{
  max-width: 500px;
  width: 90%;
  display: block;
  margin: 0 auto;
  font-size: 24px;
  padding: 7px 9px;
  background: #EC3034;
  border: none;
  outline: none;
  border-radius: 7px;
  color: #ffffff;
  font-family: 'Roboto',
  sans-serif;
  cursor: pointer;
  font-weight: 900;
  transition: 0.2s ease;
}

.form{
  padding-bottom: 50px;
}

.logo-container{
  margin: 0 auto;
  display: block;
  position: relative;
  max-width: 250px;
  width: 100%;
  margin-bottom: 25px;
}
.logo-container svg{
  width: 100%;
  height: max-content;
  display: block;
}

.form-container{
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-input-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap:3px;
}

.form-input-container label{
  color: #3a3a3a;
  font-size: 18px;
  font-family: 'Roboto',
  sans-serif;
  font-weight: 900;
}

.form-input-container input{
  font-size: 20px;
  padding: 7px 9px;
  border: none;
  border-radius:5px;
  outline: none;
  font-family: 'Roboto',
  sans-serif;
  border: 1px solid #F28F2F95;
}

.form-submit-container{
  width: 100%;
  margin-top: 10px;
}

.form-submit-container button{
  width: 90%;
  display: block;
  margin: 0 auto;
  font-size: 24px;
  padding: 7px 9px;
  background: #EC3034;
  border: none;
  outline: none;
  border-radius: 7px;
  color: #ffffff;
  font-family: 'Roboto',
  sans-serif;
  cursor: pointer;
  font-weight: 900;
  transition: 0.2s ease;
}
.form-submit-container button:hover{
  background: #F28F2F;
  color: #ffffff;
}


.form-checkbox-container{

}

.form-checkbox-container label{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: #3a3a3a;
  font-size: 18px;
  font-family: 'Roboto',
  sans-serif;
  font-weight: 900;

}
.form-checkbox-container label input{
  width: 30px;
  height: 30px;
  padding: 5px;
  accent-color: #EC3034;
}

.select-input{
  position: relative;
  display: flex;
  flex-direction: column;
}


.select-input input{

  display: block;
  position: relative;
}




.scratch-card-game{
  position: relative;
    max-width: 1000px;
    max-height: 800px;
    width: 100%;
    height: 100%;
    aspect-ratio: 1000 / 800;

    margin: 0 auto;
    background-image:radial-gradient(#EC3034 10%,#ffc92900 65%) ;
}

.scratch-card-prize{
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.scratch-card-prize-content{
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.scratch-card-prize-img{
  width: 250px;
  aspect-ratio: 1 / 1;
  object-fit: contain;
  filter: drop-shadow(0px 0px 1px #ffffff) drop-shadow(0px 0px 1px #ffffff) drop-shadow(0px 0px 1px #ffffff) drop-shadow(0px 0px 1px #ffffff) drop-shadow(0px 0px 1px #ffffff) drop-shadow(0px 0px 1px #ffffff) drop-shadow(0px 0px 0px #ffffff) drop-shadow(0px 0px 0px #ffffff);
}

.scratch-card-prize h3{
  font-size: 46px;
  font-weight: 900;
  color: #fff;
  margin: 15px 0 0 0;
  text-align: center;
}
.scratch-card-prize p{
  color: #fff;
  text-align: center;
  font-size: 22px;
  margin: 0 0 15px 0;
}

.sign-pv-button{
  background: #ffffff;
  color:#3a3a3a;
  border: none;
  outline: none;
  border-radius: 7px;
  
  display: block;
  margin: 0 auto;
  font-size: 24px;
  padding: 13px 21px;
  font-family: 'Roboto',
  sans-serif;
  cursor: pointer;
  font-weight: 900;
  transition: 0.2s ease;
}
.sign-pv-button:hover{
  background: #F28F2F;
  color: #ffffff;
}


.scratch-card-canvas{
  position: absolute;
  top: 0;
  left: 0;
 touch-action: none;
}

.scratch-card-prize svg{
  max-width: 150px;
  height: max-content;
}

.select-input{
  position: relative;
}

.select-input-options-container{
  display: none;
  flex-direction: column;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 3;
  max-height: 300px;
  overflow-y: auto;
  overflow-x:hidden;
  border-radius: 0 0 7px 7px;
}

.select-input-options-container.active{
  display: flex;
}

.select-input-option{
  text-align: left;
  padding: 7px 13px;
  background: #ffffff;
  outline: none;
  border: none;
  cursor:pointer;
  font-size: 20px;
  border-bottom: 1px solid #3a3a3a55;
  font-family: 'Roboto',
  sans-serif;
}


.select-input-option:hover {
  background: #EC3034;
  color: #ffffff;
}

body:has(.select-input-options-container.active){
  padding-bottom: 100px;
}


.form-error-container{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.form-error-message{
  background: #EC3034;
  color:#fff;
  max-width: 350px;
  padding: 25px;
  width: 100%;
  border-radius: 7px;
  border: 15px solid #F28F2F;
}

.select-input-icon{
  position: absolute;
  right: 10px;
  top:50%;
  transform:translateY(-50%);
  pointer-events: none;
}

.signature-canvas{
  background: #fff;
  border: 15px solid #F28F2F;
  display: block;
  margin: 0 auto;
}

.signature-buttons-container{
  margin-top:25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.signature-clear-button,
.signature-save-button{
 max-width: 500px;
 width: 90%;
 display: block;
  margin: 0 auto;
  font-size: 24px;
  padding: 9px 9px;
  border: none;
  outline: none;
  border-radius: 7px;
  color: #ffffff;
  font-family: 'Roboto',
  sans-serif;
  cursor: pointer;
  font-weight: 900;
  transition: 0.2s ease;
}

.signature-clear-button{
  background: #EC3034;

}

.signature-save-button{
  background:green;
}

.signature-save-button:hover,
.signature-clear-button:hover
{
  background: #F28F2F;
  color: #ffffff;
}

.signature-container{
}

.signature-container h2{
  text-align: center;
}


.end-page{
  
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
}
.end-page h2{
  text-align: center;
}

.end-page-reset-button{
  width: 90%;
  display: block;
   margin: 0 auto;
   font-size: 24px;
   padding: 9px 9px;
   border: none;
   outline: none;
   border-radius: 7px;
   color: #ffffff;
   font-family: 'Roboto',
   sans-serif;
   cursor: pointer;
   font-weight: 900;
   transition: 0.2s ease;
   background: #EC3034;
}

.end-page-reset-button:hover{
  background: #F28F2F;
}


.location-login-page{
  display: flex;
  justify-content: center;
  align-items: center;
}
.location-login-container{
  /* background: #f8efe6; */
  max-width: 450px;
    width: 100%;
    margin: 0 5px;
    border-radius: 7px;
    padding: 10px 10px 25px;
    text-align: center;
}
.location-login-form-container{
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.location-login-form-container button{
  width: 90%;
  display: block;
   margin: 0 auto;
   font-size: 24px;
   padding: 9px 9px;
   border: none;
   outline: none;
   border-radius: 7px;
   color: #ffffff;
   font-family: 'Roboto',
   sans-serif;
   cursor: pointer;
   font-weight: 900;
   transition: 0.2s ease;
   background: #EC3034;
}

.location-login-form-container button:hover{
  background: #F28F2F;
}


.location-login-form-container input{
  width: 100%;
  font-size: 30px;
  padding: 7px 9px;
  border: none;
  border-radius:5px;
  outline: none;
  font-family: 'Roboto',
  sans-serif;
  border: 1px solid #F28F2F95;
  text-align: center;
}

.location-login-error{
  text-align: center;
  color: #EC3034;
  margin: 0;
  font-size: 18px;
}

footer{
  padding-top: 250px;
  display: flex;
  justify-content: center;
}

.location-disconect-button{
  position: absolute;
  width: max-content;
  text-align: center;
  background: none;
  border: none;
  margin: 0 auto;
  display: block;
  font-size: 20px;
  z-index: 100;
}

.loser-icon{
  color: #fff;
  font-size: 150px;
}